import React from 'react'
// import Rectangle37 from '../images/Rectangle37.jpg';
import Rectangle37 from '../images/image11.jpg';
import Rectangle38 from '../images/image10.jpg';
import Rectangle39 from '../images/image9.jpg';
import line from '../images/line.svg';
import { Link } from 'react-router-dom';

const itemsData = [
    {
      title: "Coworking Spaces",
      imgSrc: Rectangle37, // Use the imported variable
      lineImgSrc: line, // Use the imported variable
      link:'/coworking-spaces'
    },
    {
      title: "Managed Offices",
      imgSrc: Rectangle38, // Use the imported variable
      lineImgSrc: line, // Use the imported variable
      link:'/managed-offices'
    },
    {
      title: "Virtual Offices",
      imgSrc: Rectangle39, // Use the imported variable
      lineImgSrc: line, // Use the imported variable
      link:'/virtual-offices'
    },
    
    // Add more data objects for additional items
  ];
  window.scrollTo(0, 0);
const Workspaces = () => {
  return (
    <>
        <section className="workspaces">
         <div className="container">
            <div className="row">
               <div className="col-md-12 text-center">
                  <h2 className="white capitalize">Workspaces For <span className="orange">Startups And Corporates</span> At Prime Spot </h2>
                  <ul>
                     {itemsData.map((item, index) => (
                     <li key={index}>
                      <Link to ={item.link}>
                        <div className="box">
                           <figure>
                              <span>
                                <img src={item.imgSrc} alt={item.title}/>
                              </span>
                           </figure>
                           <Link to={item.link}>
                            <h5>{item.title}</h5>
                            <span><img src={item.lineImgSrc} alt="Line" /></span>
                           </Link>
                        </div>
                      </Link>
                     </li>
                     ))}
                  </ul>
               </div>
            </div>
         </div>
      </section>
    </>
  )
}

export default Workspaces