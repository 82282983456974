import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { POSTS_API_URL, USERS_API_URL, CATEGORIES_API_URL, MEDIA_API_URL, NOT_FOUND_IMAGE_URL } from '../constants/api';
import { Helmet } from "react-helmet";

const CategoryBlogs = () => {
    const [posts, setPosts] = useState([]);
    const [authors, setAuthors] = useState({});
    const [categories, setCategories] = useState([]);
    const { id } = useParams();
    const [title, setTitle] = useState("Blogs");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCategoryPosts = async () => {
            try {
                setLoading(true);

                // Fetch posts for the specified category ID
                const response = await axios.get(`${POSTS_API_URL}?categories=${id}&per_page=100&_embed=true`);
                const postsData = response.data;

                const postsWithMedia = postsData.map((post) => {
                    const mediaItem = post._embedded?.['wp:featuredmedia']?.[0] || { source_url: NOT_FOUND_IMAGE_URL };
                    const authorName = post._embedded?.author?.[0]?.name || "Unknown";
                    return { ...post, mediaItem, authorName };
                });

                setPosts(postsWithMedia);

                // Fetch categories
                const categoriesResponse = await axios.get(CATEGORIES_API_URL);
                setCategories(categoriesResponse.data);

                // Set the title to the category name
                const category = categoriesResponse.data.find((cat) => cat.id === parseInt(id));
                setTitle(category?.name || "Blogs");
            } catch (error) {
                console.error('Error fetching posts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategoryPosts();
        window.scrollTo(0, 0);
    }, [id]);

    const getFirstWords = (content, wordCount) => {
        const strippedContent = content.replace(/<[^>]*>/g, ''); // Remove HTML tags
        const words = strippedContent.split(/\s+/);
        return `${words.slice(0, wordCount).join(' ')}...`;
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (posts.length === 0) {
        return <p>No posts available for this category.</p>;
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content="Category-specific blog posts." />
            </Helmet>
            <section className="blogbox">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2>{title}</h2>
                            {posts.map((post) => (
                                <div className="blogdata" key={post.id}>
                                    <div className="blogimg">
                                        <Link to={`/Blogs/${post.slug}`} className="blogbgremove" target="_blank">
                                            <img
                                                src={post.mediaItem?.source_url || "https://via.placeholder.com/150"}
                                                alt="blogpost"
                                            />
                                        </Link>
                                    </div>
                                    <div className="blogtext">
                                        <Link to={`/Blogs/${post.slug}`} target="_blank">
                                            <ul>
                                                <li>
                                                    On{" "}
                                                    <span>
                                                        {new Date(post.date).toLocaleDateString("en-US", {
                                                            year: "numeric",
                                                            month: "long",
                                                            day: "numeric",
                                                        })}
                                                    </span>
                                                </li>
                                                <li>
                                                    By <span>{post.authorName}</span>
                                                </li>
                                            </ul>
                                            <h6>{post.title.rendered}</h6>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: post.excerpt.rendered,
                                                }}
                                            />
                                            <Link className="bloglink" to={`/Blogs/${post.slug}`}>
                                                Explore More
                                            </Link>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-md-4">
                            <div className="blogsidebar">
                                <h5 className="text-center">Categories</h5>
                                <ul>
                                    {categories.length > 0 ? (
                                        categories.map((category) => (
                                            <li key={category.id}>
                                                <Link to={`/CategoryBlogs/${category.id}`} >
                                                    {category.name}
                                                </Link>
                                            </li>
                                        ))
                                    ) : (
                                        <p>No categories available.</p>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CategoryBlogs;
