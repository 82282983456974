import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { POSTS_API_URL, USERS_API_URL, CATEGORIES_API_URL, MEDIA_API_URL, NOT_FOUND_IMAGE_URL } from '../constants/api';
import { Helmet } from "react-helmet";

const Blogs = () => {
    const [posts, setPosts] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [authors, setAuthors] = useState({});
    const [categories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { id } = useParams();
    const [title, setTitle] = useState("Blogs");

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(`${POSTS_API_URL}?per_page=100`);
                const champData = Object.values(response.data);

                const postsWithMedia = await Promise.all(
                    champData.map(async (post) => {
                        const media_id = post.featured_media;
                        try {
                            if (media_id === 0) {
                                return { ...post, mediaItem: { source_url: NOT_FOUND_IMAGE_URL } };
                            } else {
                                const featuredResponse = await axios.get(`${MEDIA_API_URL}/${media_id}`);
                                return { ...post, mediaItem: featuredResponse.data };
                            }
                        } catch {
                            return { ...post, mediaItem: { source_url: NOT_FOUND_IMAGE_URL } };
                        }
                    })
                );

                setPosts(postsWithMedia);

                const authorsResponse = await axios.get(USERS_API_URL);
                const authorsData = authorsResponse.data.reduce((acc, author) => {
                    acc[author.id] = author.name;
                    return acc;
                }, {});
                setAuthors(authorsData);

                const categoriesResponse = await axios.get(CATEGORIES_API_URL);
                setCategories(categoriesResponse.data);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };

        fetchPosts();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = posts.filter((post) =>
            post.title.rendered.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredPosts(filtered);
    }, [searchQuery, posts]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredPostGt = posts.find((post) => post.slug?.trim() === id?.trim());

    const getCategoryName = (categoryId) =>
        categories.find((cat) => cat.id === categoryId)?.name || 'Uncategorized';

    const getFirstWords = (content, wordCount) => {
        const strippedContent = content.replace(/<[^>]*>/g, ''); // Remove HTML tags
        const words = strippedContent.split(/\s+/);
        return `${words.slice(0, wordCount).join(' ')}...`;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (filteredPostGt) {
            setTitle(filteredPostGt.title.rendered);
        } else {
            setTitle("Blogs");
        }
    }, [filteredPostGt]);

    if (!filteredPostGt) {
        return <p>Loading____!</p>;
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content="Frequently Asked Questions for Ofis Square." />
            </Helmet>
            <section className="headingimage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div key={filteredPostGt.id}>
                                <span className="catname">
                                    {filteredPostGt.categories?.map((catId) => getCategoryName(catId)).join(', ')}
                                </span>
                                <h2>{filteredPostGt.title.rendered}</h2>
                                <ul>
                                    <li>
                                        {new Date(filteredPostGt.date).toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        })}
                                    </li>
                                    <li>0 Comments</li>
                                </ul>
                                <img
                                    src={filteredPostGt.mediaItem?.source_url || NOT_FOUND_IMAGE_URL}
                                    alt={filteredPostGt.title.rendered}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sigleblogtext blogbox">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="singlealldata" key={filteredPostGt.id}>
                                <p dangerouslySetInnerHTML={{ __html: filteredPostGt.content.rendered }} />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="blogsidebar">
                                <form>
                                    <input
                                        type="text"
                                        placeholder="Enter Keyword..."
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </form>
                            </div>

                            <div className="blogsidebar">
                                <h5 className="text-center">Categories</h5>
                                <ul>
                                    {categories.length > 0 ? (
                                        categories.map((category) => (
                                            <li key={category.id}>
                                                <Link to={`/CategoryBlogs/${category.id}`}>
                                                    {category.name}
                                                </Link>
                                            </li>
                                        ))
                                    ) : (
                                        <p>No categories available.</p>
                                    )}
                                </ul>
                            </div>

                            <div className="blogsidebar">
                            <h5 className="text-center">Popular Post</h5>
                            {posts
                                .filter(
                                (post) =>
                                    post.categories.some((catId) => filteredPostGt.categories.includes(catId)) &&
                                    post.id !== filteredPostGt.id // Exclude the main blog itself
                                )
                                .sort(() => Math.random() - 0.5) // Shuffle the array randomly
                                .slice(0, 10) // Limit to 10 posts
                                .map((post, i) => (
                                <Link to={`/Blogs/${post.slug}`} key={i} className="blogpop">
                                    {post.mediaItem && (
                                    <img src={post.mediaItem.source_url} alt="blogpost" />
                                    )}
                                    <span>
                                    {post.categories
                                        ?.map((catId) => categories.find((cat) => cat.id === catId)?.name)
                                        .filter(Boolean) // Remove undefined category names
                                        .join(", ")}
                                    </span>
                                    <p
                                    dangerouslySetInnerHTML={{
                                        __html: getFirstWords(post.content.rendered, 10),
                                    }}
                                    />
                                </Link>
                                ))}
                            </div>



                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Blogs;
