import React from 'react'
import Zomato from "../images/Zomato.png";
import Aeon from "../images/Aeon.jpg";
import MEDIAKART from "../images/MEDIAKART.png";
import Mastek_logo from "../images/Mastek_logo.png";
import Isfc_logo from "../images/ifsc.png";
import Gigaforce_Brand_ID_Logo from "../images/Gigaforce_Brand_ID_Logo.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const AboutTrustedCompanies = () => {
    const itemsData = [
        {
            imgsrcEllipse1: Zomato,
            // desc: "Has a tastefully done interior and is kept delightfully clean",
            // name: 'Wade Warren',
            // dept: 'Marketing Coordinator',
        },
        {
            imgsrcEllipse1: Aeon,
            // desc: "Has a tastefully done interior and is kept delightfully clean",
            // name: 'Wade Warren',
            // dept: 'Marketing Coordinator',
        },
        {
            imgsrcEllipse1: MEDIAKART,
            // desc: "Has a tastefully done interior and is kept delightfully clean",
            // name: 'Wade Warren',
            // dept: 'Marketing Coordinator',
        },
        {
            imgsrcEllipse1: Mastek_logo,
            // desc: "Has a tastefully done interior and is kept delightfully clean",
            // name: 'Wade Warren',
            // dept: 'Marketing Coordinator',
        },
        {
            imgsrcEllipse1: Isfc_logo,
            // desc: "Has a tastefully done interior and is kept delightfully clean",
            // name: 'Wade Warren',
            // dept: 'Marketing Coordinator',
        },
        {
            imgsrcEllipse1: Gigaforce_Brand_ID_Logo,
            // desc: "Has a tastefully done interior and is kept delightfully clean",
            // name: 'Wade Warren',
            // dept: 'Marketing Coordinator',
        }
        
    ];
    const options = {
        nav: false,
        autoplay: true,
        dots: false,
        responsive: {
            0: {
                items: 2
            },
            600: {
                items: 3
            },
            1000: {
                items: 5
            }
        }
    };
    return (
        <>
            <section className="leadingcompanies">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Our Clients</h2>
                            <OwlCarousel className="owl-carousel owl-loaded owl-drag" {...options} autoplay margin={15}>
                                {itemsData.map((item, index) => (
                                    <div className="item" key={index}>
                                        <div className="box">
                                            <img src={item.imgsrcEllipse1} />
                                            {/* <h6>{item.desc}</h6>
                                            <p>{item.name}</p>
                                            <span>{item.dept}</span> */}
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutTrustedCompanies