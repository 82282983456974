import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header'
import About from './components/About'
import Blog from './components/Blog'
import Main from './components/Main'
import Footer from './components/Footer'
import Aerocity from './components/Aerocity';
import CoworkingspacesNoida from './components/CoworkingspacesNoida';
import Sector62Noida from './components/Sector62Noida';
import NearBySectorSonaRoadGurugram from './components/NearBySectorSonaRoadGurugram';
import SonaRoadGurugram from './components/SonaRoadGurugram';
import MgRoadGurugram from './components/MgRoadGurugram';
import CoworkingspacesGurugram from './components/CoworkingspacesGurugram';
import SonaRoadDemandbook from './components/SonaRoadDemandbook';
import Sector62NoidaDemandbook from './components/Sector62NoidaDemandbook';
import NearcenterMgRoadGurugram from './components/NearcenterMgRoadGurugram';
import NoidaSector3Demandbook from './components/NoidaSector3Demandbook';
import NearcenterNoidaSector3 from './components/NearcenterNoidaSector3';
import Contact from './components/Contact';
import Blogs from './components/Blogs';
import Career from './components/Career';
import NoidaSector3 from './components/NoidaSector3';
import ManagedOffices from './components/ManagedOffices';
import LetsGurugramSpacing from './components/LetsGurugramSpacing';
import VirtualOffices from './components/VirtualOffices';
import EventSpaces from './components/EventSpaces';
import DayPass from './components/DayPass';
import MeetingRoom from './components/MeetingRoom';
import ConferenceRoom from './components/ConferenceRoom';
import BoardRoom from './components/BoardRoom';
import Erorr404 from './components/Erorr404';
import RequestQuote from './components/RequestQuote';
import ComingSoon from './components/ComingSoon';
import News from './components/News';
import InNews from './components/InNews';
import FAQ from './components/FAQ';
import CoworkingSpaces from './components/Coworkingspaces';
import OpenTerraceGarden from './components/OpenTerraceGarden';
import LetsAbout from './components/LetsAbout';
import EventsSpace from './components/EventsSpace';
import NewsPage from './components/NewsPage';
import MaverickPage from './components/MaverickPage';
import CategoryBlogs from './components/CategoryBlogs';

const App = () => {
  return (
    <Router>
     <>
      <Header/>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/About" Component={About}/>
        <Route path="/Blog" Component={Blog}/>
        <Route path="/contact-us" Component={Contact}/>
        <Route path="/Aerocity" Component={Aerocity}/>
        <Route path="/Blogs/:id" Component={Blogs}/>
        <Route path="/Career/" Component={Career}/>
        <Route path="/CategoryBlogs/:id" Component={CategoryBlogs}/>
        <Route path="/coworking-spaces-in-noida" Component={CoworkingspacesNoida}/>
        <Route path="/coworking-space-in-noida-sectore-62" Component={Sector62Noida}/>
        <Route path="/coworking-space-in-gurugram-sohna-road" Component={SonaRoadGurugram}/>
        <Route path="/coworking-space-in-gurugram-mg-road" Component={MgRoadGurugram}/>
        <Route path="/coworking-space-in-noida-sectore-3" Component={NoidaSector3}/>
        <Route path="/coworking-spaces-in-gurugram" Component={CoworkingspacesGurugram}/>
        <Route path="/coworking-spaces" Component={CoworkingSpaces}/>
        <Route path="/managed-offices" Component={ManagedOffices}/>
        <Route path="/virtual-offices" Component={VirtualOffices}/>
        <Route path="/event-spaces" Component={EventSpaces}/>
        <Route path="/NearBySectorSonaRoadGurugram/" Component={NearBySectorSonaRoadGurugram}/>
        <Route path="/SonaRoadDemandbook/" Component={SonaRoadDemandbook}/>
        <Route path="/Sector62NoidaDemandbook/" Component={Sector62NoidaDemandbook}/>
        <Route path="/NearcenterMgRoadGurugram/" Component={NearcenterMgRoadGurugram}/>
        <Route path="/NoidaSector3Demandbook/" Component={NoidaSector3Demandbook}/>
        <Route path="/NearcenterNoidaSector3/" Component={NearcenterNoidaSector3}/>
        <Route path="/LetsGurugramSpacing/" Component={LetsGurugramSpacing}/>
        <Route path="/DayPass/" Component={DayPass}/>
        <Route path="/MeetingRoom/" Component={MeetingRoom}/>
        <Route path="/ConferenceRoom/" Component={ConferenceRoom}/>
        <Route path="/BoardRoom/" Component={BoardRoom}/>
        <Route path="/RequestQuote/" Component={RequestQuote}/>
        <Route path="/ComingSoon/" Component={ComingSoon}/>
        <Route path="/News/" Component={News}/>
        {/* <Route path="/in-the-news/" Component={InNews}/> */}
        <Route path="/in-the-news/" Component={NewsPage}/>
        <Route path="/FAQ/" Component={FAQ}/>
        <Route path="/OpenTerraceGarden/" Component={OpenTerraceGarden}/>
        <Route path="/LetsAbout/" Component={LetsAbout}/>
        <Route path="/EventsSpace/" Component={EventsSpace}/>
        {/* <Route path="/NewsPage/" Component={NewsPage}/> */}
        <Route path="/Maverick/" Component={MaverickPage}/>
        <Route path="*" element={<Erorr404 />} />
      </Routes>
      <Footer/>
      </>
    </Router>
  )
}

export default App