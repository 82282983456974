import React from 'react'
import Slider from './Slider'
import Workspaces from './Workspaces'
import Demandbook from './Demandbook'
import Upcomingpg from './Upcomingpg'
import Wosquare from './Wosquare'
import Testimonials from './Testimonials'
import Aplatform from './Aplatform'
import News from './News'
import Lets from './Lets'
import PopularNews from './PopularNews'
import { Helmet } from "react-helmet";
const Main = () => {
  return (
    <>
      <main>
      <Helmet>
                <title>Home</title>
              
            </Helmet>
        <Slider />
        <Workspaces />
        <Demandbook />
        <Upcomingpg />
      
        <Testimonials /> 
        <Aplatform />
        <PopularNews/>
        <News />
        <Lets />
        <Wosquare />
      </main>
    </>
  )
}

export default Main